<template>
  <data-list
    ref="dataList"
    class="content"
    :add-btn="null"
    url="/user"
    name="权限"
    hide-edit
    hide-delete
    :query="query"
    :columns="columns"
    :default-query="defaultQuery"
    :search-key-type="{ name: true }"
  >
    <template #title>
      <span>用户列表</span>
    </template>
    <template v-slot:action="{ item }">
      <a class="txt-btn" @click="EditDetail(item)">修改权限</a>
    </template>
    <template v-slot:query="{ form }">
      <a-form-model-item label="">
        <a-select
          style="width: 180px"
          @change="updateList"
          v-model="form.role"
          :options="options"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input v-model="form.mobile" placeholder="输入手机号查询" />
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }" ref="updateItem">
      <a-form-item label="姓名">
        <a-input v-model="form.real_name" disabled />
      </a-form-item>
      <a-form-item label="昵称">
        <a-input v-model="form.uname" disabled />
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-model="form.mobile" disabled />
      </a-form-item>
      <a-form-item label="角色">
        <a-select
          :default-value="form.role == 1 ? '普通用户' : '管理员'"
          style="width: 120px"
          v-model="form.role"
          :options="options"
        >
        </a-select>
      </a-form-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "IdentityList",
  components: {},
  data() {
    return {
      defaultQuery: { role: 2 },
      options: [
        { key: 1, title: "普通用户" },
        { key: 2, title: "管理员" },
      ],
      columns: [
        { title: "姓名", dataIndex: "real_name" },
        { title: "昵称", dataIndex: "uname" },
        { title: "手机号", dataIndex: "mobile" },
        {
          title: "角色",
          customRender: (text, record) => {
            return <span>{record.role == 1 ? "普通用户" : "管理员"}</span>;
          },
        },
      ],
    };
  },
  watch: {},
  computed: {
    query() {
      let res = "&sort=-id";
      let info = this.info;
      if (info) {
        res += `&filter[user_id]=${info.id}`;
      }
      return res;
    },
  },
  methods: {
    EditDetail(item, index) {
      this.$refs.dataList.editRow(item, index);
    },
    updateList() {
      this.$refs.dataList.getList();
    },
  },
};
</script>
<style scoped lang="less">
</style>
